import ticketsContract from './Tickets.json'
import winnersContract from './Winners.json'

export const ticketsAbi = ticketsContract.abi
export const winnersAbi = winnersContract.abi

export const sepoliaTicketsContractAddress =
  process.env.VUE_APP_SEPOLIA_TICKETS_CONTRACT_ADDRESS
export const sepoliaWinnersContractAddress =
  process.env.VUE_APP_SEPOLIA_WINNERS_CONTRACT_ADDRESS

export const mainnetTicketsContractAddress =
  process.env.VUE_APP_MAINNET_TICKETS_CONTRACT_ADDRESS
export const mainnetWinnersContractAddress =
  process.env.VUE_APP_MAINNET_WINNERS_CONTRACT_ADDRESS
