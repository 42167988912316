<template>
  <div>
    <TabWithAmounts :tabs="tabs" @switch-tab="handleChange" />
    <br />
    <ControllerTabWithMonths @switch-tab="handleChange" />
    <br />
    <ControllerTotals />
    <ControllerFilteredTotals
      :controllerDashboardData="controllerDashboardData"
    />
    <ControllerWinnersBoard
      :firstPlaceAmount="firstPlaceAmount"
      :secondPlaceAmount="secondPlaceAmount"
      :filteredData="controllerWinnersData"
      :poolCode="poolCode"
    />
    <ControllerContractBalance />
    <ControllerPaymentBoard :poolCode="poolCode" />
  </div>
</template>

<script>
import ControllerTotals from '@/components/atoms/ControllerTotals'
import ControllerFilteredTotals from '@/components/atoms/ControllerFilteredTotals'
import ControllerWinnersBoard from '@/components/atoms/ControllerWinnersBoard'
import ControllerPaymentBoard from '@/components/molecules/ControllerPaymentBoard'
import ControllerContractBalance from '@/components/atoms/ControllerContractBalance'
import ControllerTabWithMonths from '@/components/atoms/ControllerTabWithMonths'
import TabWithAmounts from '@/components/atoms/TabWithAmounts'
import TICKET_VALUES from '../../utils/ticket_values.json'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ControllerBoard',
  components: {
    ControllerTotals,
    ControllerFilteredTotals,
    ControllerWinnersBoard,
    ControllerPaymentBoard,
    ControllerContractBalance,
    ControllerTabWithMonths,
    TabWithAmounts,
  },
  data() {
    return {
      firstPlaceAmount: 0,
      secondPlaceAmount: 0,
      poolCode: '',
      tabs: TICKET_VALUES.ticketValues,
      controllerDashboardData: [],
      controllerWinnersData: [],
      filterObject: {},
    }
  },
  computed: {
    ...mapGetters(['allTickets']),
  },
  methods: {
    ...mapMutations(['setIsPoolPassed']),
    getMonth(monthStr) {
      return new Date(monthStr + '-1-01').getMonth() + 1
    },
    isInThePast(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    checkPoolTimeLeft(val) {
      const poolDate =
        val.slice(3) + '-' + this.getMonth(val.slice(0, 3)) + '-28'
      if (poolDate.length >= 9) {
        this.isPoolPassed = this.isInThePast(new Date(poolDate))
        this.setIsPoolPassed(this.isPoolPassed)
      }
    },
    handleChange(val) {
      this.checkPoolTimeLeft(val)
      val.length === 7
        ? (this.filterObject.month = val)
        : (this.filterObject.type = val)
      const type = this.filterObject.type ? this.filterObject.type : null
      const month = this.filterObject.month ? this.filterObject.month : null
      const poolCode = type + month
      const result = this.allTickets.filter(
        (option) =>
          option.poolType === this.filterObject.type &&
          option.month === this.filterObject.month
      )

      this.controllerWinnersData = result
      this.controllerDashboardData = result
      this.poolCode = poolCode
    },
  },
}
</script>
