<template>
  <section class="impact-section">
    <h2 class="section-header">{{ STRINGS.impacto }}</h2>
    <div class="impact-list">
      <div
        class="impact-item"
        v-for="(item, index) in impactItems"
        :key="index"
      >
        <img :src="item.image" :alt="item.title" class="impact-image" />
        <div class="impact-text">
          <h3 class="impact-title">{{ item.title }}</h3>
          <p class="impact-description">{{ item.description }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { STRINGS } from '../../utils/strings'
export default {
  data() {
    return {
      STRINGS: STRINGS,
      impactItems: [
        {
          title: 'Apoyo a ONGs Locales',
          description:
            'Una parte de los fondos se destina a ONGs locales que comparten nuestra misión de conservación ambiental y sostenibilidad. Tus contribuciones ayudan a estas organizaciones a continuar con su trabajo vital.',
          image: require('@/assets/support.png'),
        },
        {
          title: 'Eventos de Restauración Ambiental',
          description:
            'Organizamos y financiamos eventos dedicados tanto a la reforestación como a la recolección de basura. Estas iniciativas reúnen a las comunidades para plantar árboles, restaurar hábitats naturales y limpiar nuestras costas y otras áreas naturales. Al abordar tanto la reforestación como la eliminación de desechos, ayudamos a proteger y rejuvenecer el medio ambiente.',
          image: require('@/assets/events.png'),
        },
        {
          title: 'Iniciativas de Plantación de Árboles',
          description:
            'Siempre que sea posible, plantamos árboles directamente. Esto no solo ayuda en los esfuerzos de reforestación, sino que también contribuye a la compensación de carbono y a la biodiversidad.',
          image: require('@/assets/reforest.png'),
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.impact-section {
  padding: 22px 0px 88px;
  background-color: #f9f9f9;
}

.section-header {
  text-align: center;
  margin-bottom: 39px;
  color: $secondary;
  font-size: 55px;
  font-family: 'Patua One', cursive;
}

.impact-list {
  display: flex;
  justify-content: center;
}

.impact-item {
  align-items: center;
  margin-bottom: 1.5rem;
  max-width: fit-content;
  margin: 0 33px;
}

.impact-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1rem;
}

.impact-text {
  flex: 1;
  max-width: 411px;
}

.impact-title {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
  color: #555;
}

.impact-description {
  font-size: 1rem;
  color: #666;
}
@media (max-width: 1089px) {
  .impact-list {
    display: block;
  }
  .section-header {
    font-size: 33px;
  }
}
</style>
