import { notify } from '@kyvg/vue3-notification'
const { ethereum } = window

const state = {
  currentAccount: '',
}
const getters = {
  currentAccount: (state) => state.currentAccount,
  isAdmin: (state) =>
    state.currentAccount.toLowerCase() ===
    process.env.VUE_APP_MAINNET_ADMIN_ACCOUNT.toLowerCase(),
}
const actions = {
  async connectWallet({ commit }) {
    try {
      const result = await ethereum.request({ method: 'eth_requestAccounts' })
      if (result.length) {
        commit('setCurrentAccount', result[0])
      }
    } catch (e) {
      notify({ title: 'Please install Metamask', type: 'warn' })
      console.error(e)
    }
  },
  async checkIfWalletIsConnect({ commit }) {
    try {
      const result = await ethereum.request({ method: 'eth_accounts' })
      result.length ? commit('setCurrentAccount', result[0]) : null
    } catch (e) {
      console.error(e)
    }
  },
}
const mutations = {
  setCurrentAccount: (state, data) => (state.currentAccount = data),
}

export default {
  state,
  getters,
  actions,
  mutations,
}
