export const MONTH_TABS = [
  { text: 'Jul - 2024', value: 'jul2024' },
  { text: 'Aug - 2024', value: 'aug2024' },
  { text: 'Sep - 2024', value: 'sep2024' },
  { text: 'Oct - 2024', value: 'oct2024' },
  { text: 'Nov - 2024', value: 'nov2024' },
  { text: 'Dec - 2024', value: 'dec2024' },
  { text: 'Jan - 2025', value: 'jan2025' },
  { text: 'Feb - 2025', value: 'feb2025' },
  { text: 'Mar - 2025', value: 'mar2025' },
  { text: 'Apr - 2025', value: 'apr2025' },
  { text: 'May - 2025', value: 'may2025' },
  { text: 'Jun - 2025', value: 'jun2025' },
  { text: 'Jul - 2025', value: 'jul2025' },
  { text: 'Aug - 2025', value: 'aug2025' },
  { text: 'Sep - 2025', value: 'sep2025' },
  { text: 'Oct - 2025', value: 'oct2025' },
  { text: 'Nov - 2025', value: 'nov2025' },
  { text: 'Dec - 2025', value: 'dec2025' },
  { text: 'Jan - 2026', value: 'jan2026' },
  { text: 'Feb - 2026', value: 'feb2026' },
  { text: 'Mar - 2026', value: 'mar2026' },
  { text: 'Apr - 2026', value: 'apr2026' },
  { text: 'May - 2026', value: 'may2026' },
  { text: 'Jun - 2026', value: 'jun2026' },
  { text: 'Jul - 2026', value: 'jul2026' },
  { text: 'Aug - 2026', value: 'aug2026' },
  { text: 'Sep - 2026', value: 'sep2026' },
  { text: 'Oct - 2026', value: 'oct2026' },
  { text: 'Nov - 2026', value: 'nov2026' },
  { text: 'Dec - 2026', value: 'dec2026' },
  { text: 'Jan - 2027', value: 'jan2027' },
  { text: 'Feb - 2027', value: 'feb2027' },
  { text: 'Mar - 2027', value: 'mar2027' },
  { text: 'Apr - 2027', value: 'apr2027' },
  { text: 'May - 2027', value: 'may2027' },
  { text: 'Jun - 2027', value: 'jun2027' },
  { text: 'Jul - 2027', value: 'jul2027' },
  { text: 'Aug - 2027', value: 'aug2027' },
  { text: 'Sep - 2027', value: 'sep2027' },
  { text: 'Oct - 2027', value: 'oct2027' },
  { text: 'Nov - 2027', value: 'nov2027' },
  { text: 'Dec - 2027', value: 'dec2027' },
  { text: 'Jan - 2028', value: 'jan2028' },
  { text: 'Feb - 2028', value: 'feb2028' },
  { text: 'Mar - 2028', value: 'mar2028' },
  { text: 'Apr - 2028', value: 'apr2028' },
  { text: 'May - 2028', value: 'may2028' },
  { text: 'Jun - 2028', value: 'jun2028' },
  { text: 'Jul - 2028', value: 'jul2028' },
  { text: 'Aug - 2028', value: 'aug2028' },
  { text: 'Sep - 2028', value: 'sep2028' },
  { text: 'Oct - 2028', value: 'oct2028' },
  { text: 'Nov - 2028', value: 'nov2028' },
  { text: 'Dec - 2028', value: 'dec2028' },
  { text: 'Jan - 2029', value: 'jan2029' },
  { text: 'Feb - 2029', value: 'feb2029' },
  { text: 'Mar - 2029', value: 'mar2029' },
  { text: 'Apr - 2029', value: 'apr2029' },
  { text: 'May - 2029', value: 'may2029' },
  { text: 'Jun - 2029', value: 'jun2029' },
  { text: 'Jul - 2029', value: 'jul2029' },
  { text: 'Aug - 2029', value: 'aug2029' },
  { text: 'Sep - 2029', value: 'sep2029' },
  { text: 'Oct - 2029', value: 'oct2029' },
  { text: 'Nov - 2029', value: 'nov2029' },
  { text: 'Dec - 2029', value: 'dec2029' },
  { text: 'Jan - 2030', value: 'jan2030' },
  { text: 'Feb - 2030', value: 'feb2030' },
  { text: 'Mar - 2030', value: 'mar2030' },
  { text: 'Apr - 2030', value: 'apr2030' },
  { text: 'May - 2030', value: 'may2030' },
  { text: 'Jun - 2030', value: 'jun2030' },
  { text: 'Jul - 2030', value: 'jul2030' },
  { text: 'Aug - 2030', value: 'aug2030' },
  { text: 'Sep - 2030', value: 'sep2030' },
  { text: 'Oct - 2030', value: 'oct2030' },
  { text: 'Nov - 2030', value: 'nov2030' },
  { text: 'Dec - 2030', value: 'dec2030' },
]
