<template>
  <div class="winners-container">
    <div class="row">
      <h2>{{ STRINGS.winnersDraw }}</h2>
      <div v-if="poolCode.length >= 10" class="indicators">
        <h4 v-if="isPoolPassed" class="danger">Countdown Finished</h4>
        <h4 v-if="!isPoolPassed" class="success">Countdown not finished</h4>
        <h4 v-if="poolAlreadySettled" class="danger">
          Pool winners already settled
        </h4>
        <h4 v-if="!poolAlreadySettled" class="success">
          Pool winners not settled
        </h4>
      </div>
    </div>
    <div class="row">
      <h3>{{ STRINGS.firstPlace }}</h3>
      <span class="address">{{ firstPlaceAddress }}</span>
    </div>
    <div class="row">
      <h3>{{ STRINGS.secondPlace }}</h3>
      <span class="address">{{ firstPlaceAddress }}</span>
    </div>
    <div class="row">
      <button
        class="buy-btn"
        :disabled="poolCode.length <= 10 || poolAlreadySettled || !isPoolPassed"
        @click="getWinners"
      >
        {{ STRINGS.selectWinners }}
      </button>
    </div>
    <br />
    <div class="row">
      <button
        class="buy-btn"
        :disabled="poolCode.length <= 10 || poolAlreadySettled || !isPoolPassed"
        @click="setSendWinners"
      >
        {{ STRINGS.sendWinners }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { STRINGS } from '../../utils/strings'

export default {
  name: 'ControllerWinnersBoard',
  props: {
    filteredData: {
      type: Array,
    },
    poolCode: {
      type: String,
    },
    firstPlaceAmount: {
      type: [Number, String],
    },
    secondPlaceAmount: {
      type: [Number, String],
    },
  },
  data() {
    return {
      firstPlaceAddress: '',
      secondPlaceAddress: '',
      STRINGS: STRINGS,
    }
  },
  computed: {
    ...mapGetters(['winners', 'isPoolPassed']),
    poolAlreadySettled() {
      const result = this.winners.filter(
        (option) => option.pool_code === this.poolCode
      )
      return result.length >= 1
    },
  },
  methods: {
    ...mapActions([
      'createFirstPlaceStruct',
      'createSecondPlaceStruct',
      'sendWinnersToBlockchain',
    ]),
    setSendWinners() {
      this.sendWinnersToBlockchain()
      this.firstPlaceAddress = ''
      this.secondPlaceAddress = ''
    },
    async getWinners() {
      let first = {}
      let second = {}
      let addressesArray = this.filteredData.map((option) => option.ticketOwner)
      let random = Math.floor(Math.random() * addressesArray.length)
      const poolType = this.poolCode.slice(0, -7)

      const firstPlace = addressesArray[random]
      this.firstPlaceAddress = firstPlace
      first.amount = this.firstPlaceAmount
      first.address = firstPlace
      first.pool_code = this.poolCode
      first.pool_standing = 'first'
      first.pool_type = poolType
      this.createFirstPlaceStruct(first)
      const firstPlaceIndex = addressesArray.indexOf(firstPlace)
      if (firstPlaceIndex >= 0) addressesArray.splice(firstPlaceIndex, 1)

      const secondPlace = addressesArray[random]
      this.secondPlaceAddress = secondPlace
      second.amount = this.secondPlaceAmount
      second.address = secondPlace
      second.pool_code = this.poolCode
      second.pool_standing = 'second'
      second.pool_type = poolType
      this.createSecondPlaceStruct(second)
      const secondPlaceIndex = addressesArray.indexOf(secondPlace)
      if (secondPlaceIndex >= 0) addressesArray.splice(secondPlaceIndex, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
.winners-container {
  padding: 22px;
  text-align: left;

  .row {
    display: flex;
    justify-content: space-between;

    .address {
      width: 100%;
      max-width: 444px;
      height: 19px;
    }
  }
}

.buy-btn {
  @extend .BUTTON;
  max-height: 42px;

  &:hover {
    background-color: $secondary;
  }
  &:disabled {
    background-color: #7a85a3c7;
    cursor: not-allowed;
  }
}
.danger {
  color: red;
  font-weight: bold;
}
.success {
  color: #0bd50b;
  font-weight: bold;
}
.indicators {
  text-align: left;
  font-size: 18px;
  margin: 0 22px 0 0;
}
</style>
