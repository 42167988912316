export const STRINGS = {
  impacto: 'Cómo Generamos Impacto',
  archive: 'Archivos',
  ethalottoTotal: 'Total',
  winnersPayment: 'Pago a Ganadores',
  addressCount: 'Número de Direcciones',
  pricePool: 'Charcos Mensuales',
  selectMonth: 'Seleccionar mes',
  ticketBooth: 'Punto de Venta',
  selectBuyin: 'Seleccionar tiquete',
  yourTickets: 'Tus Tiquetes',
  ticketAmount: 'Cantidad',
  ticketEntryDate: 'Fecha',
  ticketWalletAddress: 'Dirección',
  eth: ' eth, ',
  ethalotto: "El Charco d' Ether",
  home: 'Inicio',
  buyTicket: 'Comprar Tiquete',
  enterLottery: 'Participar en la Lotería',
  controller: 'Controlador',
  howDoesItWork: 'Cómo funciona',
  termsAndConditions: 'Términos y condiciones',
  oceanCleanup: 'Limpieza del océano',
  firstPlace: 'Ganador',
  secondPlace: 'Segundo Lugar',
  orderSummary: 'Resumen del pedido',
  selectedMonth: 'Mes Seleccionado',
  orderTotal: 'Total a Pagar',
  ticketType: 'Tipo de Tiquete x (cantidad)',
  agreeTerms: 'Acepto los términos y condiciones',
  winnersDraw: 'Sorteo de Ganadores',
  selectWinners: 'Seleccionar ganadores',
  sendWinners: 'Enviar ganadores a la blockchain',
  position: 'Posición',
  address: 'Dirección',
  totals: 'Totales',
  ethalottoAndOceanCleanup: "El Charco d' Ether y Limpieza del Océano",
  totalTickets: 'Tiquetes Totales',
  totalEthereum: 'Ethereum Total',
  homeTitle:
    'Juega, Contribuye, Gana: Únete a Nuestra Lotería Ecológica de Ethereum',
  homeSubTitle:
    'Apoya Causas Ambientales y Gana Ethereum en Nuestras Loterías Mensuales',
  filteredTotals: 'Totales Filtrados',
  connectWallet: 'Conectar a Metamask',
  chooseMonthAndType: 'Selecciona Tipo y Mes',
  winnerSelected: 'Esperar Sorteo',
  recievePrice: 'Recibe tus Ganancias',
  connectMetamask: 'Conecta tu billetera Metamask.',
  selectBuyinMonth:
    'Elige tu mes de lotería y tipo de tiquete: Micro, Pequeño, Medio o Grande. Cada tipo ofrece diferentes precios y niveles de participación.',
  lotteries:
    'Espera los resultados de la lotería el 28 de cada mes. Aumenta tus probabilidades comprando múltiples tiquetes.',
  transfers:
    'Las ganancias se envían a las direcciones de billetera participantes dentro de las 48 horas. Las direcciones de los ganadores se muestran en la plataforma.',
  reforestationFirst:
    'Los paisajes en Panamá están siendo cada vez más afectados por la expansión urbana, carreteras, minería y prácticas agrícolas, como la ganadería, donde se despejan campos de árboles. Estas actividades contribuyen a la deforestación, amenazando el delicado equilibrio de nuestros ecosistemas y disminuyendo la belleza natural que define nuestro país.',
  reforestationSecond:
    'La pérdida de árboles afecta la flora y fauna local y altera la capacidad del medio ambiente para sostenerse, llevando a la erosión del suelo, reducción de la biodiversidad e impactos negativos en nuestro clima.',
  reforestationThird:
    'La reforestación es una preocupación primordial y una razón clave por la que estamos recaudando fondos. Al apoyar nuestras iniciativas, estás contribuyendo a la restauración de estas áreas vitales, ayudándonos a plantar árboles y revivir los ecosistemas que se han perdido. Juntos, podemos trabajar hacia un futuro más verde y sostenible para Panamá.',
  oceanCleanupFirst:
    'Panamá es rico en biodiversidad, con una impresionante variedad de especies de plantas y animales tanto en tierra como en sus aguas. El país alberga 220 especies de peces de agua dulce, 1,157 especies de peces marinos y el 3.4% de las especies de anfibios del mundo, lo que lo convierte en un lugar verdaderamente único y fascinante para explorar.',
  oceanCleanupSecond:
    'Sin embargo, las costas y ríos enfrentan amenazas crecientes por la contaminación, especialmente por la acumulación de basura en ríos cercanos a áreas urbanas. Estos desafíos ambientales ejercen una enorme presión sobre nuestros recursos naturales y vida silvestre.',
  oceanCleanupThird:
    'Esta situación urgente es una de las principales razones para recaudar fondos. Al apoyar nuestra causa, nos estás ayudando a tomar medidas para proteger las costas de Panamá y preservar su increíble biodiversidad para las generaciones futuras.',
  totalRaised: 'Total Recaudado',
  totalGivedBack: 'Total Pagado',
  termsHeader: 'TÉRMINOS Y CONDICIONES',
  termsIntroduction:
    "Estos Términos y Condiciones del Sitio Web escritos en esta página web regulan el uso de nuestro sitio web, El Charco d' Ether, accesible en charcodether.org.",
  termsIntroductionSecond:
    'Al utilizar nuestro Sitio Web, aceptas estos términos y condiciones en su totalidad. Si no estás de acuerdo con estos términos y condiciones o con cualquier parte de ellos, no debes usar nuestro Sitio Web.',
  termsDescription:
    "El Charco d' Ether consiste en una loteria mensual donde los participantes pueden comprar tiquetes usando Ethereum. La lotería se llevará a cabo el 28 de cada mes, y los ganadores serán determinados a través de un proceso de selección aleatoria, guardando todos los tiquetes y ganadores en la blockchain de Ethereum.",
  termsPriceDistribution:
    'La distribución de premios para las loterías será la siguiente:',
  termsPriceDistribution40:
    'El 55% del fondo de premios total se destinará al ganador del primer lugar',
  termsPriceDistribution25:
    'El 25% del fondo de premios total se destinará al ganador del segundo lugar',
  termsPriceDistribution16:
    'El 20% del fondo de premios total se destinará a iniciativas de limpieza del océano y costas',
  termsPriceDistribution4:
    'El 4% del fondo de premios total se destinará al desarrollo y mantenimiento de la plataforma',
  termsPurchase:
    "Para participar en la lotería, debes comprar un tiquete usando Ethereum. El precio de los tiquetes será determinado por El Charco d' Ether y puede variar con el tiempo. Todas las compras de tiquetes son finales y no reembolsables.",
  termsPurchaseSecond:
    'Al comprar un tiquete y participar en la lotería, reconoces que estás al tanto y aceptas los términos y condiciones establecidos en este documento.',
  termsWinners:
    'Los ganadores de la lotería serán determinados a través de un proceso de selección aleatoria, guardando todos los tiquetes y ganadores en la blockchain de Ethereum. La selección de los ganadores será definitiva y no negociable.',
  termsClaiming:
    'Los ganadores recibirán el premio en la misma dirección utilizada para comprar el tiquetes dentro de las 48 horas posteriores a la resolución de la lotería.',
  termsTaxes:
    "Todos los ganadores son responsables de pagar los impuestos aplicables sobre sus premios. El Charco d' Ether no es responsable de pagar ningún impuesto en nombre de los ganadores.",
  termsLiability:
    "El Charco d' Ether no será responsable por ningún daño o pérdida de cualquier tipo que surja o esté relacionado con la lotería o el uso de los premios.",
  termsChangeTerms:
    "El Charco d' Ether se reserva el derecho de cambiar los términos y condiciones de la lotería en cualquier momento sin previo aviso. Es responsabilidad de los participantes mantenerse informados sobre cualquier cambio en los términos y condiciones.",
  termsLaw:
    'Estos términos y condiciones se regirán e interpretarán de acuerdo con las leyes de Panamá y cualquier disputa estará sujeta a la jurisdicción exclusiva de los tribunales de Panamá.',
  termsLawSecond:
    'Al participar en la lotería, reconoces que has leído y aceptas estar sujeto a estos términos y condiciones.',
  altBay:
    'Una vista desalentadora de la bahía de Panamá y su acumulación de basura',
  altTurtle: 'Esta especie de tortuga está entre las más abundantes en Panamá',
  ariaDiscord: 'Únete a nuestra comunidad en Discord',
  ariaIg: 'Únete a nuestra comunidad en Instagram',
}
