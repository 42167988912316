import { ethers } from 'ethers'
import {
  ticketsAbi,
  mainnetTicketsContractAddress,
} from '../../utils/constants'
import { notify } from '@kyvg/vue3-notification'

const API_KEY = process.env.VUE_APP_API_KEY

const state = {
  selectedTicketType: '',
  selectedTicketValue: 0,
  selectedMonthValue: '',
  filterObject: { type: '', month: '' },
  isLoading: false,
  allTickets: [],
  ethBalance: 0,
  timerPoolDateCode: '',
  poolsData: [],
  ticketData: [],
  isPoolPassed: false,
}
const getters = {
  selectedTicketType: (state) => state.selectedTicketType,
  selectedTicketValue: (state) => state.selectedTicketValue,
  selectedMonthValue: (state) => state.selectedMonthValue,
  userTicketCount: (state) => state.ticketData.length,
  totalTicketCount: (state) => state.poolsData.length,
  keyword: (state) => state.selectedTicketType + state.selectedMonth,
  filterObject: (state) => state.filterObject,
  isLoading: (state) => state.isLoading,
  allTickets: (state) => state.allTickets,
  ethBalance: (state) => state.ethBalance,
  timerPoolDateCode: (state) => state.timerPoolDateCode,
  poolsData: (state) => state.poolsData,
  ticketData: (state) => state.ticketData,
  isPoolPassed: (state) => state.isPoolPassed,
}
const actions = {
  async getBalance({ commit }) {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const ticketsContract = new ethers.Contract(
          mainnetTicketsContractAddress,
          ticketsAbi,
          signer
        )
        const transactionsHash = await ticketsContract.getBalance()
        const data = parseInt(transactionsHash._hex) / 10 ** 18
        commit('setEthBalance', data)
      }
    } catch (e) {
      console.error(e)
    }
  },
  async withdrawFromContract({ dispatch }, data) {
    try {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum)
        const signer = provider.getSigner()
        const ticketsContract = new ethers.Contract(
          mainnetTicketsContractAddress,
          ticketsAbi,
          signer
        )
        const parsedAmount = ethers.utils.parseEther(data.amount.toString())
        const tx = await ticketsContract.transferEther(
          data.address,
          parsedAmount._hex
        )
        await tx.wait()
        notify({ title: 'Succesfully withdraw from contract🎉' })
        dispatch('getBalance')
      }
    } catch (e) {
      console.error(e)
    }
  },
  async sendTransaction({ commit, getters, dispatch, state }) {
    const {
      selectedMonthValue,
      selectedTicketType,
      selectedTicketValue,
      isLoading,
      isPoolPassed,
    } = state

    const canSendTransaction =
      selectedMonthValue &&
      selectedTicketType &&
      selectedTicketValue &&
      !isLoading &&
      !isPoolPassed

    if (!canSendTransaction) {
      console.warn('Cannot send transaction. Required values are missing.')
      return
    }
    commit('setIsLoading', true)
    try {
      if (!window.ethereum) {
        throw new Error('Ethereum provider not found')
      }
      const provider = new ethers.providers.Web3Provider(window.ethereum)
      const signer = provider.getSigner()
      const ticketsContract = new ethers.Contract(
        mainnetTicketsContractAddress,
        ticketsAbi,
        signer
      )
      const parsedAmount = ethers.utils.parseEther(
        selectedTicketValue.toString()
      )
      const ticketsHash = await ticketsContract.buyTicket(
        parsedAmount,
        getters.currentAccount,
        selectedMonthValue,
        selectedTicketType,
        getters.keyword,
        { value: parsedAmount._hex }
      )
      await ticketsHash.wait()
      await new Promise((resolve) => setTimeout(resolve, 4444))

      notify({
        title: `Successfully bought (1) ${selectedTicketType} ticket for ${selectedMonthValue} lottery 🎉`,
      })

      dispatch('getAllTickets')
    } catch (error) {
      console.error(error)

      if (error?.error?.code === -32000) {
        notify({ title: 'Insufficient Funds 😿', type: 'warn' })
      }
    } finally {
      commit('setIsLoading', false)
    }
  },
  async getAllTickets({ commit, dispatch }) {
    try {
      const provider = new ethers.providers.JsonRpcProvider(
        `https://eth-mainnet.g.alchemy.com/v2/${API_KEY}`
      )
      const ticketsContract = new ethers.Contract(
        mainnetTicketsContractAddress,
        ticketsAbi,
        provider
      )
      const allTicketsHash = await ticketsContract.getAllTickets()
      const allTicketsParced = allTicketsHash.map((ticket) => ({
        ticketOwner: ticket.ticket_owner,
        timestamp: new Date(
          ticket.timestamp.toNumber() * 1000
        ).toLocaleString(),
        poolType: ticket.pool_type,
        month: ticket.month,
        keyword: ticket.keyword,
        amount: parseInt(ticket.amount._hex) / 10 ** 18,
      }))
      commit('setAllTickets', allTicketsParced)
      dispatch('handleFiltering')
    } catch (e) {
      console.error(e)
    }
  },
  handleMonthOrTicketChange({ dispatch }) {
    dispatch('handleFiltering')
  },
  handleFiltering({ commit, getters, dispatch }) {
    const type = getters.selectedTicketType
    const month = getters.selectedMonthValue
    const filteredPoolData = getters.allTickets.filter(
      (option) => option.poolType === type && option.month === month
    )
    commit('setPoolsData', filteredPoolData)
    if (getters.currentAccount) {
      const filteredTicketDataByAccount = state.poolsData.filter(
        (option) =>
          option.ticketOwner.toLowerCase() ===
          getters.currentAccount.toLowerCase()
      )
      commit('setTicketDataByAccount', filteredTicketDataByAccount)
    } else {
      commit('setTicketDataByAccount', [])
    }
  },
}
const mutations = {
  setSelectedTicketValue: (state, data) => (state.selectedTicketValue = data),
  setSelectedTicketType: (state, data) => {
    state.filterObject.type = data
    state.selectedTicketType = data
  },
  setSelectedMonthValue: (state, data) => {
    state.selectedMonthValue = data
    state.filterObject.month = data
  },
  setIsLoading: (state, data) => (state.isLoading = data),
  setAllTickets: (state, data) => (state.allTickets = data),
  setEthBalance: (state, data) => (state.ethBalance = data),
  setTimerPoolDateCode: (state, data) => (state.timerPoolDateCode = data),
  setPoolsData: (state, data) => (state.poolsData = data),
  setTicketDataByAccount: (state, data) => (state.ticketData = data),
  setIsPoolPassed: (state, data) => {
    state.isPoolPassed = data
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
