<template>
  <div class="banner-slider">
    <transition
      name="fade"
      @before-enter="beforeEnter"
      @enter="enter"
      @leave="leave"
    >
      <component :is="currentBanner" key="currentBanner" />
    </transition>
  </div>
</template>

<script>
import OceanCleanupBanner from './OceanCleanupBanner.vue'
import ReforestationBanner from './ReforestationBanner.vue'

export default {
  components: {
    OceanCleanupBanner,
    ReforestationBanner,
  },
  data() {
    return {
      banners: [OceanCleanupBanner, ReforestationBanner],
      currentIndex: 0,
      bannerChangeInterval: null,
    }
  },
  computed: {
    currentBanner() {
      return this.banners[this.currentIndex]
    },
  },
  methods: {
    switchBanner(index) {
      this.currentIndex = index
    },
    nextBanner() {
      this.currentIndex = (this.currentIndex + 1) % this.banners.length
    },
    startBannerRotation() {
      this.bannerChangeInterval = setInterval(this.nextBanner, 33000)
    },
    stopBannerRotation() {
      if (this.bannerChangeInterval) {
        clearInterval(this.bannerChangeInterval)
      }
    },
    beforeEnter(el) {
      el.style.opacity = 0
    },
    enter(el, done) {
      el.offsetHeight // trigger reflow
      el.style.transition = 'opacity 3s'
      el.style.opacity = 1
      done()
    },
    leave(el, done) {
      el.style.transition = 'opacity 3s'
      el.style.opacity = 0
      done()
    },
  },
  mounted() {
    this.startBannerRotation()
  },
  beforeDestroy() {
    this.stopBannerRotation()
  },
}
</script>

<style lang="scss" scoped>
.banner-slider {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: $light-purple;
}

.controls {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
}

button {
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0 5px;
  padding: 10px;
  cursor: pointer;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Transition styles */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
