<template>
  <div class="bingo-board">
    <div v-if="showArchive" class="archive-wrapper">
      <div class="header archive">{{ STRINGS.archive }}</div>
      <ArchivePage />
      <button class="archive-btn" @click="setShowArchive">
        {{ STRINGS.pricePool }}<i class="fa-solid fa-angles-right"></i>
      </button>
    </div>
    <div class="pool-wrapper" v-else>
      <div class="header">{{ STRINGS.pricePool }}</div>
      <div class="pick">{{ STRINGS.selectMonth }}</div>
      <LotteryTabWithMonths
        @switch-tab="handleMonthChange"
        @formatted-date="handleInitialMonth"
      />
      <TimerContainer @end-time="handleEndTime" />
      <PoolTotals :isPoolPassed="isPoolPassed" />
      <button class="archive-btn" @click="setShowArchive">
        <i class="fa-solid fa-angles-left"></i> {{ STRINGS.archive }}
      </button>
    </div>
  </div>
</template>

<script>
import ArchivePage from '@/components/molecules/ArchivePage'
import LotteryTabWithMonths from '@/components/atoms/LotteryTabWithMonths'
import TimerContainer from '@/components/atoms/TimerContainer'
import PoolTotals from '@/components/atoms/PoolTotals'
import { STRINGS } from '../../utils/strings'

import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'PoolsBoard',
  components: {
    ArchivePage,
    LotteryTabWithMonths,
    PoolTotals,
    TimerContainer,
  },
  data() {
    return {
      STRINGS: STRINGS,
      showArchive: false,
      isPoolPassed: false,
      endTime: '',
    }
  },
  methods: {
    ...mapActions(['filterTickets']),
    ...mapMutations(['setSelectedMonthValue', 'setIsPoolPassed']),
    getMonth(monthStr) {
      return new Date(monthStr + '-1-01').getMonth() + 1
    },
    isInThePast(date) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      return date < today
    },
    handleEndTime(val) {
      this.endTime = val
    },
    checkPoolTimeLeft(val) {
      const poolDate =
        val.slice(3) + '-' + this.getMonth(val.slice(0, 3)) + '-28'
      if (poolDate.length >= 9) {
        this.isPoolPassed = this.isInThePast(new Date(poolDate))
        this.setIsPoolPassed(this.isPoolPassed)
      }
    },
    handleInitialMonth(val) {
      if (val) this.checkPoolTimeLeft(val)
    },
    handleMonthChange(val) {
      this.checkPoolTimeLeft(val)
      this.setSelectedMonthValue(val)
      this.$emit('monthChange', val)
    },
    setShowArchive() {
      this.showArchive = !this.showArchive
      this.$emit('show-archive')
    },
  },
  mounted() {
    this.handleInitialMonth()
  },
}
</script>
<style scoped lang="scss">
.bingo-board {
  border: 1px solid lightgrey;
  background: white;
  overflow: visible;
  border-radius: 6px;
  height: 590px;
  position: relative;
  width: 777px;
}
.archive-wrapper {
  padding: 0 15px 22px;
  overflow: hidden;
  max-height: 515px;
  overflow-y: scroll;
  scroll-behavior: smooth;
}
.pool-wrapper {
  padding: 0 15px 22px;
  margin: 0 auto;
  overflow: hidden;
  max-height: 600px;
}
.header {
  font-family: 'Patua One';
  font-size: 33px;
  font-weight: bold;
  color: $primary;
  padding: 22px 0 0 0;

  &.archive {
    color: $secondary;
  }
}
.pick {
  font-size: 12px;
  font-weight: bold;
  margin: 4px 4px 7px;
  text-align: left;

  & i {
    color: $primary;
    cursor: pointer;
  }
}

// Create wrapper component
.archive-btn {
  border: none;
  background: none;
  padding: 11px 0;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
  left: 22px;
  bottom: 0;
  cursor: pointer;
  color: $primary-grey;

  &:hover {
    color: $secondary;
  }
}
@media (max-width: 1650px) {
  .bingo-board {
    width: 100%;
    margin-top: 44px;
  }
}
</style>
