<template>
  <div class="terms">
    <h3>{{ STRINGS.termsHeader }}</h3>
    <ol>
      <li>Introducción</li>
      <p>{{ STRINGS.termsIntroduction }}</p>
      <p>{{ STRINGS.termsIntroductionSecond }}</p>
      <li>Descripción de la Lotería</li>
      <p>{{ STRINGS.termsDescription }}</p>
      <li>Distribución de Premios</li>
      <p>{{ STRINGS.termsPriceDistribution }}</p>
      <ul>
        <p>{{ STRINGS.termsPriceDistribution40 }}</p>
        <p>{{ STRINGS.termsPriceDistribution25 }}</p>
        <p>{{ STRINGS.termsPriceDistribution16 }}</p>
        <p>{{ STRINGS.termsPriceDistribution4 }}</p>
      </ul>
      <li>Compra de Boletos y Participación</li>
      <p>{{ STRINGS.termsPurchase }}</p>
      <p>{{ STRINGS.termsPurchaseSecond }}</p>
      <li>Selección de Ganadores</li>
      <p>{{ STRINGS.termsWinners }}</p>
      <li>Reclamación de Premios</li>
      <p>{{ STRINGS.termsClaiming }}</p>
      <li>Impuestos</li>
      <p>{{ STRINGS.termsTaxes }}</p>
      <li>Limitación de Responsabilidad</li>
      <p>{{ STRINGS.termsLiability }}</p>
      <li>Cambios en los Términos y Condiciones</li>
      <p>{{ STRINGS.termsChangeTerms }}</p>
      <li>Ley Aplicable</li>
      <p>{{ STRINGS.termsLaw }}</p>
      <p>{{ STRINGS.termsLawSecond }}</p>
    </ol>
  </div>
</template>

<script>
import { STRINGS } from '../utils/strings'
export default {
  name: 'TermsView',
  data() {
    return {
      STRINGS: STRINGS,
    }
  },
}
</script>

<style scoped lang="scss">
.terms {
  padding: 34px 353px;
  text-align: justify;

  h3 {
    text-align: center;
  }
  li {
    font-weight: bold;
  }
}
@media (max-width: 1333px) {
  .terms {
    padding: 34px 222px;
  }
}
@media (max-width: 1089px) {
  .terms {
    padding: 34px 111px;
  }
}
@media (max-width: 777px) {
  .terms {
    padding: 11px 22px;
  }
}
</style>
