<template>
  <div>
    <h3>{{ STRINGS.filteredTotals }}</h3>
    <table class="filteredTable">
      <tr>
        <td>{{ STRINGS.addressCount }}</td>
        <td>{{ STRINGS.totalTickets }}</td>
        <td>{{ STRINGS.totalEthereum }}</td>
        <td>{{ STRINGS.firstPlace }}</td>
        <td>{{ STRINGS.secondPlace }}</td>
        <td>{{ STRINGS.oceanCleanup }}</td>
        <td>{{ STRINGS.ethalotto }}</td>
        <td>{{ STRINGS.ethalottoTotal }}</td>
      </tr>
      <tr>
        <td>{{ filteredAddressCount }}</td>
        <td>{{ filteredTotalTickets }}</td>
        <td>{{ filteredEthTotal }}</td>
        <td>{{ filteredFirstPlace }}</td>
        <td>{{ filteredSecondPlace }}</td>
        <td>{{ filteredMotherNature }}</td>
        <td>{{ filteredEthalottoOrg }}</td>
        <td>{{ filteredEthalottoTotal }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TICKET_VALUES from '../../utils/ticket_values.json'
import { STRINGS } from '../../utils/strings'

export default {
  name: 'ControllerFilteredTotals',
  props: {
    controllerDashboardData: {
      type: Array,
    },
  },
  data() {
    return {
      tabs: TICKET_VALUES.ticketValues,
      STRINGS: STRINGS,
    }
  },
  computed: {
    ...mapGetters(['allTickets']),
    filteredAddressCount() {
      let addresses = this.controllerDashboardData.map(
        (option) => option.ticketOwner
      )
      let unique = [...new Set(addresses)]
      return unique.length
    },
    filteredTotalTickets() {
      const total = this.controllerDashboardData.length
      return total
    },
    filteredEthTotal() {
      const total = this.controllerDashboardData.reduce(
        (a, b) => a + (b['amount'] || 0),
        0
      )
      return total.toFixed(4)
    },
    filteredFirstPlace() {
      const total = this.controllerDashboardData.reduce(
        (a, b) => a + (b['amount'] || 0),
        0
      )
      const result = total * (55 / 100)
      return result.toFixed(4)
    },
    filteredSecondPlace() {
      const total = this.controllerDashboardData.reduce(
        (a, b) => a + (b['amount'] || 0),
        0
      )
      const result = total * (25 / 100)
      return result.toFixed(4)
    },
    filteredMotherNature() {
      const total = this.controllerDashboardData.reduce(
        (a, b) => a + (b['amount'] || 0),
        0
      )
      const result = total * (16 / 100)
      return result.toFixed(4)
    },
    filteredEthalottoOrg() {
      const total = this.controllerDashboardData.reduce(
        (a, b) => a + (b['amount'] || 0),
        0
      )
      const result = total * (4 / 100)
      return result.toFixed(4)
    },
    filteredEthalottoTotal() {
      const total = this.controllerDashboardData.reduce(
        (a, b) => a + (b['amount'] || 0),
        0
      )
      const result = total * (20 / 100)
      return result.toFixed(4)
    },
  },
}
</script>

<style lang="scss" scoped>
.filteredTable {
  margin: 33px auto;
  text-align: center;

  tr,
  td {
    padding: 0 33px 0 0;
    margin: 0 auto;
  }
}
</style>

<style scoped lang="scss">
.my-navbar {
  margin-bottom: 12px;
}
</style>
