<template>
  <div>
    <div class="checkout-container">
      <div class="header-container">
        <h1 class="checkout-header">{{ STRINGS.orderSummary }}</h1>
        <i
          :class="[
            'far fa-check-circle',
            !isPoolPassed ? 'passed' : 'not-passed',
          ]"
        ></i>
      </div>
      <div class="row code">
        <h4>{{ STRINGS.selectedMonth }}</h4>
        <h4>{{ selectedMonthValue ? selectedMonthValue : '-' }}</h4>
      </div>
      <div class="row count">
        <h4>{{ STRINGS.ticketType }}</h4>
        <h4>
          {{ selectedTicketType }}
          {{
            !!selectedTicketValue && !!selectedTicketType
              ? '(' + 1 + ')'
              : '(' + 0 + ')'
          }}
        </h4>
      </div>
      <div class="row total">
        <h4>{{ STRINGS.orderTotal }}</h4>
        <h4>{{ selectedTicketValue }} ETH</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { STRINGS } from '../../utils/strings'

export default {
  name: 'OrderSummary',
  data() {
    return {
      termsCheckbox: false,
      STRINGS: STRINGS,
      showTermsCheckbox: false,
    }
  },
  computed: {
    ...mapGetters([
      'selectedTicketType',
      'selectedTicketValue',
      'selectedMonthValue',
      'isPoolPassed',
    ]),
  },
}
</script>
<style scoped lang="scss">
.checkout-container {
  font-family: 'Patua One', cursive;
  margin-bottom: 11px;

  & .checkout-header {
    margin: 0 0 9px;
    text-align: left;
    color: $primary;
    font-size: 19px;
  }
  & .row {
    display: flex;
    justify-content: space-between;
    margin: 6px 0;

    & h4 {
      margin: 0;
      font-size: 14px;
    }
  }
}
.code,
.count {
  h4 {
    font-weight: 100;
  }
}
.total {
  h4 {
    font-weight: bold;
  }
}
.terms {
  display: flex;
  text-align: left;
  justify-content: space-between;
  margin: 11px 0;
}
.header-container {
  display: flex;

  & .fa-check-circle {
    margin: 5px;
    font-size: 15px;
  }

  & .passed {
    color: #0bd50b;
  }

  & .not-passed {
    color: #f2de27;
  }
}
</style>
