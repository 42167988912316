<template>
  <div class="home">
    <div class="play-container">
      <PoolsBoard
        :isPoolPassed="poolPassed"
        @show-archive="toggleShowTicketBoard"
        @monthChange="handleMonthOrTicketChange"
      />
      <TicketBoard
        v-if="!archiveHidden"
        @typeChange="handleMonthOrTicketChange"
      />
    </div>
  </div>
</template>

<script>
import TicketBoard from '@/components/molecules/TicketBoard'
import PoolsBoard from '@/components/molecules/PoolsBoard'
import { mapActions } from 'vuex'

export default {
  name: 'LotteryView',
  components: {
    TicketBoard,
    PoolsBoard,
  },
  data() {
    return {
      poolPassed: false,
      archiveHidden: false,
    }
  },
  methods: {
    ...mapActions([
      'handleMonthOrTicketChange',
      'getAllTickets',
      'getAllWinners',
    ]),
    toggleShowTicketBoard() {
      this.archiveHidden = !this.archiveHidden
    },
  },
  created() {
    this.getAllTickets()
    this.getAllWinners()
  },
}
</script>
<style scoped lang="scss">
.home {
  padding: 77px 0 222px;

  .play-container {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1650px) {
  .home {
    padding: 34px 169px 222px;

    .play-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .play-container > :nth-child(1) {
      order: 1;
    }
  }
}
@media (max-width: 1333px) {
  .home {
    padding: 34px 111px 222px;
  }
}
@media (max-width: 777px) {
  .home {
    padding: 11px 22px 222px;
  }
}
</style>
